export class CategoryConstants {
  static readonly savingCategoryCodes = [
    'short-term-invest',
    'short-term-invest-plus',
    'short-term-invest-dollars',
    'short-term-invest-dollars-plus',
  ];

  static readonly dollarWallet = 'caja-moneda';
}
